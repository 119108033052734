import React from 'react'

import { Charts } from '@jiaminghi/data-view-react'

import './RightChart2.less'

const option = {
  series: [
    {
      type: 'pie',
      data: [
        { name: '康复', value: 93 },
        { name: '特教', value: 66 },
        { name: '职训', value: 52 },
        // { name: '社工', value: 34 }
      ],
      radius: ['45%', '65%'],
      insideLabel: {
        show: false,
      },
      outsideLabel: {
        labelLineEndLength: 10,
        formatter: '{percent}%\n{name}',
        style: {
          fontSize: 14,
          fill: '#fff',
        },
      },
    },
  ],
  color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
}

const getConfig = (data) => {

  let items = [];
  data.items.forEach(i => {
    items.push({ name: i.name, value: i.value * 1 });
  })

  return {
    series: [
      {
        type: 'pie',
        data: items,
        radius: ['45%', '65%'],
        insideLabel: {
          show: false,
        },
        outsideLabel: {
          labelLineEndLength: 10,
          formatter: '{percent}%\n{name}',
          style: {
            fontSize: 14,
            fill: '#fff',
          },
        },
      },
    ],
    color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
  };
}

const getTotal = (data) => {

  let total = 0;
  data.items.forEach(i => {
    total = total + 1 * i.value;
  });
  return total;
};

export default ({ data }) => {

  return <div className="right-chart-2">
    <div className="rc1-header">职工占比</div>
    <div className="rc1-chart-container">
      <div className="left">
        <div className="number">{getTotal(data)}</div>
        <div>总人数</div>
      </div>

      <Charts className="right" option={getConfig(data)} />
    </div>
  </div>;
}
