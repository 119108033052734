import React from 'react'

import { ActiveRingChart, Decoration2 } from '@jiaminghi/data-view-react'

import LabelTag from './LabelTag'

import './BottomCharts.less'

const style = { 
  //width: '5px' 
  // width: '50px' 
  width: '10px' 
}

const getView = (data, ps) => {

  const getConfig = () => {
    const d = [];
    data.items.forEach(i => {
      d.push({ name: i.name, value: i.value * 1 });
    })

    let c = {
      data: d,
      lineWidth: 12,
      color: ['#00baff', '#3de7c9',  '#ffc530', '#469f4b'],
      radius: '60%',
      activeRadius: '60%',
      digitalFlopStyle: {
        fontSize: 20,
        //fill: 'red'
      }
      /**
       * '#fff',
      digitalFlopStyle: {
        fontSize: 25,
        fill: 'red'
      }
         */
    }

    if (ps) {
      c = { ...c, ...ps };
    }

    return c;
  };

  const getLabelConfig = () => {
    let d = [];
    data.items.forEach(i => {
      d.push(i.name);
    });
  };

  return <>
    <ActiveRingChart config={getConfig()} style={{ height: '180px'}}  />
  </>;
}

export default ({ result }) => {
  return (
    <div className="bottom-charts">
      <div className="bc-chart-item">
        <div className="bcci-header">儿童</div>
        {
          result['儿童'] && getView(result['儿童'], {
            showOriginValue: true
          })
        }
      </div>
      <Decoration2 className="decoration-1" reverse={true} style={style} />
      <div className="bc-chart-item">
        <div className="bcci-header">性別</div>
        {
          result['性別'] && getView(result['性別'])
        }
      </div>
      <Decoration2 className="decoration-1" reverse={true} style={style} />
      <div className="bc-chart-item">
        <div className="bcci-header">服务</div>
        {
          result['服务'] && getView(result['服务'])
        }
      </div>
      <Decoration2 className="decoration-2" reverse={true} style={style} />
      <div className="bc-chart-item">
        <div className="bcci-header">教育</div>
        {
          result['教育'] && getView(result['教育'])
        }
      </div>
      <Decoration2 className="decoration-3" reverse={true} style={style} />
    </div>
  )
}