import React from 'react'
import { Tabs } from 'antd';

import Data from './Data';

function Setting() {

    return (

        <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: '基础设施',
                    key: '1',
                    children: <Data title={"基础设施"} />,
                },
                {
                    label: '融合方向',
                    key: '2',
                    children: <Data title={"融合方向"} />,
                },
                {
                    label: '职工占比',
                    key: '3',
                    children: <Data title={"职工占比"} />,
                },
                {
                    label: '儿童',
                    key: '4',
                    children: <Data title={"儿童"} />,
                },
                {
                    label: '性別',
                    key: '5',
                    children: <Data title={"性別"} />,
                },
                {
                    label: '服务',
                    key: '6',
                    children: <Data title={"服务"} />,
                },
                {
                    label: '教育',
                    key: '7',
                    children: <Data title={"教育"} />,
                },
            ]}
        />
    )
}

export default Setting
