import React from 'react'

import { CapsuleChart } from '@jiaminghi/data-view-react'

import './LeftChart3.less'

const config = {
  data: [],
  colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
  unit: '件',
}

const getConfig = (data) => {
  return {
    data:data.items,
    colors: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
    unit: '人',
  };
}

export default ({ data }) => {

  const getTotal = (data) => {

    let total = 0;
    data.items.forEach(i => {
      total = total + 1 * i.value;
    });
    return total;
  };

  return (
    <div className="left-chart-3">
      <div className="rc1-header">融合方向</div>
      <br />
      <div className="lc3-details">
        总人数<span>{(getTotal(data))}</span>
      </div>
      <br />
      <CapsuleChart className="lc3-chart" config={getConfig(data)} />
    </div>);

}
