import React from 'react'

import DataV from './components/datav'

import './App.css'

function App() {
  return (
    <div id="app">
      <DataV />
    </div>
  )
}

export default App
