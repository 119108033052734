import React, { useState, useEffect } from 'react'

import {
  FullScreenContainer,
  BorderBox2,
  BorderBox1,
  BorderBox3,
  BorderBox4,
} from '@jiaminghi/data-view-react'

import LeftChart1 from './LeftChart1'
import LeftChart2 from './LeftChart2'
import LeftChart3 from './LeftChart3'

import CenterCmp from './CenterCmp'

import RightChart1 from './RightChart1'
import RightChart2 from './RightChart2'

import BottomCharts from './BottomCharts'
import Login from '../Login';
import { useRequest } from '@umijs/hooks';
import { list } from '../../service';

import './index.less'

const style = {
  width: '120px',
  height: '50px',
  lineHeight: '50px',
  textAlign: 'center',
  marginLeft: '200px',
}

export default () => {

  const [open, setOpen] = useState(false);
  const [result, setResult] = useState();
  const { run: fetch } = useRequest(
    () => {
      return list({ code: '0019' });
    },
    {
      manual: true,
      onSuccess: (resp) => {
        if (resp) {
          let values = {};
          resp.forEach(i => {
            if (i.items && i.items.length > 0) {
              values[i.title] = i;
            }
          });
          setResult(values);
        }
      }
    },
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div id="data-view">
      <FullScreenContainer>
        <div className="main-header">
          <div className="mh-left"></div>
          <div className="mh-middle">重庆市爱心庄园</div>
          <div className="mh-right" onClick={() => {
            setOpen(true);
          }}>
            <BorderBox2 style={style}>综合管理台</BorderBox2>
          </div>
          <Login open={open}
            onClose={() => {
              setOpen(false);
              fetch();
            }} />
        </div>
        <BorderBox1 className="main-container">
          <BorderBox3 className="left-chart-container">
            {
              // OK
              false && <>
                {result && result['基础设施'] && <LeftChart1 data={result['基础设施']} />}
              </>

            }
            {
              true && <>
                {
                  false && <LeftChart2 />
                }
                {
                    // OK
                    result && result['融合方向'] && <LeftChart3 data={result['融合方向']} />
                  }

              </>
            }
          </BorderBox3>
          <div className="right-main-container">
            <div className="rmc-top-container">
              <BorderBox3 className="rmctc-left-container">
                <CenterCmp />
              </BorderBox3>

              <div className="rmctc-right-container">
                <BorderBox3 className="rmctc-chart-1">
                  {
                    result && result['基础设施'] && <RightChart1 data={result['基础设施']} />
                  }
                </BorderBox3>
                <BorderBox4 className="rmctc-chart-2" reverse={true}>
                  {
                    result && result['职工占比'] && <RightChart2 data={result['职工占比']} />
                  }
                </BorderBox4>
              </div>
            </div>
            {
              result && <BorderBox4 className="rmc-bottom-container">
                <BottomCharts result={result} />
              </BorderBox4>
            }
          </div>
        </BorderBox1>
      </FullScreenContainer>
    </div>
  )
}