import React, { useState } from 'react'
import { Button, Modal } from 'antd';
import {
    LockOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    LoginForm,
    ProFormText
} from '@ant-design/pro-components';
import { useRequest } from '@umijs/hooks';
import { login } from '../service';
import { set } from '../utils/authority';
import Setting from './Setting';

function Page({ open, onClose }) {

    const [token, setToken] = useState();

    const { run: submit } = useRequest(
        (values) => {
            return login(values);
        },
        {
            manual: true,
            onSuccess: ({ token }) => {
                console.log('success')
                set(token);
                setToken(token);
            },
        },
    );

    console.log('token:',token);

    return (
        <Modal title="资料设定"
            width={"70%"}
            open={open}
            onCancel={() => {
                onClose();
            }}
            footer={[
                <Button key="close"
                    onClick={() => {
                        setToken(undefined);
                        onClose();
                    }}>
                    关闭
                </Button>,
            ]}
        >
            {
                token ? <Setting /> : <LoginForm onFinish={(values) => {
                    submit(values);
                }}>
                    <ProFormText
                        name="username"
                        fieldProps={{
                            size: 'large',
                            prefix: <UserOutlined className={'prefixIcon'} />,
                        }}
                        rules={[
                            {
                                required: true,
                                message: '请输入用户名!',
                            },
                        ]}
                    />
                    <ProFormText.Password
                        name="password"
                        fieldProps={{
                            size: 'large',
                            prefix: <LockOutlined className={'prefixIcon'} />,

                        }}
                        placeholder={'密码'}
                        rules={[
                            {
                                required: true,
                                message: '请输入密码！',
                            },
                        ]}
                    />
                </LoginForm>
            }
        </Modal >
    )
}

export default Page;