import request from './utils/request';

//const server = 'http://192.168.10.225:9966'
// const server = 'https://ls.xinyiniao.cn';
const server = '';

export async function login({ username, password }) {
    return request(`${server}/api/login/execute`, {
        method: 'POST',
        data: { type: 'account', key: username, value: password },
    });
}

export async function get(title) {

    return request(`${server}/api/dp`, {
        method: 'POST',
        data: { title },
    });

    /**
    return new Promise((resolve, reject) => {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                resolve(JSON.parse(value));
            } else {
                resolve([]);
            }
        } catch (error) {
            reject(error);
        }
    });
     */
}

export async function set(title, items) {
    let values = [];
    items.forEach(i => {
        values.push({ name: i.name, value: i.value });
    });

    return request(`${server}/api/dp`, {
        method: 'PUT',
        data: JSON.stringify({ title, items: values }),
    });

    /**
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem(key, values);
            resolve();
        } catch (error) {
            reject(error);
        }
    });
     */
}

export async function list({ code }) {
    return request(`${server}/api/dp/list/${code}`, {
        method: 'GET'
    });
    /**
    return new Promise((resolve, reject) => {
        try {
            let v = {};
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                v[key] = JSON.parse(localStorage.getItem(key));
            }
            resolve(v);
        } catch (error) {
            reject(error);
        }
    });
     */
}


