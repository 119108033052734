
export function getToken() {
  return localStorage.getItem('token');
}

export function get() {
  return {
    token: localStorage.token,
  };
}

export function set(token) {
  
  /**
   * add token and refreshToken
   */
  localStorage.setItem('token', token);
}

export function logout() {
  localStorage.clear();
}