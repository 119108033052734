import { extend } from 'umi-request';
import { notification } from 'antd';
import { getToken, logout } from './authority';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const errorMapping = {
  10004: '登录失敗',
  JWT_TOKEN_EXPIRED: '登录失效.请重新登录',
  11012: '该手机号已被使用',
  10010: '该账号已被注册',
  11010: '验证码错误',
  10005: '该账号已被注册',
  90200: '授权失败',
  GU01: '群体已被使用.不可删除',
  90006: '档案已被使用.请先删除关联的报告',
  60022: '删除失败.请先移除相关联的设定',
  60013: '提交内容错误.请检核提交的内容'
};

const errorHandler = error => {
  const {
    response,
    data: { errorCode, message },
  } = error;

  if (response && response.status) {

    if (response.status === 401) {

      logout()
    }

    if (errorCode && errorMapping[errorCode]) {

      /**
       * 授權失敗
       */
      if (errorCode === '90200') {

        console.log('errorCode:', errorCode);
      } else {
        /**
      * 自定義錯誤
      */
        if (response.status !== 401) {
          notification.error({
            message: '请求错误',
            description: errorMapping[errorCode],
          });
        }
      }

    } else {
      const errorText = codeMessage[response.status] || message;
      const { status, url } = response;
      if (status !== 401) {
        notification.error({
          message: `请求错误 ${status}: ${url}`,
          description: errorText,
        });
      }
    }
  }

  return response;
};
/**
 * 配置request请求时的默认参数
 */

const request = extend({
  headers: {
    'Content-Type': 'application/json',
  },
  errorHandler,
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// eslint-disable-next-line consistent-return
request.interceptors.request.use(async (url, options) => {

  const token = getToken();
  if (token) {
    let headers = {
      'X-Authorization': `Bearer ${token}`
    };

    if (options.requestType && options.requestType === 'form') {
      // 
    } else {
      headers = { ...headers, 'Content-Type': 'application/json' };
    }

    return {
      options: { ...options, headers },
    };
  }
  return {
    options: { ...options },
  };
});

export default request;
