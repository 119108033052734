import React from 'react'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import './RightChart1.less'

export default ({ data }) => {

  const getConfig = () => {

    const values = [];
    data.items.forEach(item => {
      values.push([
        item.name, item.value
      ])
    });

    return {
      // header: ['设施', '数量'],
      data: values,
      index: true,
      columnWidth: [50, 200, 50],
      align: ['center'],
      rowNum: 5,
      headerBGC: '#1981f6',
      // headerHeight: 45,
      //oddRowBGC: 'rgba(0, 44, 81, 0.8)',
      //evenRowBGC: 'rgba(10, 29, 50, 0.8)',
    };
  }

  return <div className="right-chart-1">
    <div className="rc1-header" style={{ margin: '10px' }}> 基础设施 </div>
    <div className="rc1-chart-container">
      <ScrollBoard config={getConfig()} />
    </div>
  </div>;
}