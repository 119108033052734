import React, { useState, useEffect, useRef } from 'react'
import { notification, Button } from 'antd';
import { EditableProTable } from '@ant-design/pro-components';
import { useRequest } from '@umijs/hooks';
import { get, set } from '../service';

function Data({ title }) {

    const form = useRef();
    const [editableKeys, setEditableRowKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 200,
            formItemProps: {
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
        {
            title: '內容',
            width: 150,
            dataIndex: 'value',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
        {
            title: '操作',
            valueType: 'option',
            width: 60,
            render: () => {
                return null;
            },
        },
    ];

    const { run: fetch } = useRequest(
        () => {
            return get(title);
        },
        {
            manual: true,
            onSuccess: ({ items }) => {
                setDataSource(items.map((i, id) => ({
                    id, name: i.name, value: i.value
                })));
            }
        },
    );

    const { run: submit } = useRequest(
        (values) => {
            return set(title, values);
        },
        {
            manual: true,
            onSuccess: () => {
                notification['success']({
                    message: '保存成功', placement: 'bottomRight'
                });
                fetch();
            },
        },
    );

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setEditableRowKeys(dataSource.map((item) => item.id));
    }, [dataSource.length]);

    return (
        <EditableProTable
            columns={columns}
            rowKey="id"
            editableFormRef={form}
            value={dataSource}
            onChange={setDataSource}
            recordCreatorProps={{
                newRecordType: 'dataSource',
                record: () => ({
                    id: dataSource.length + 1,
                }),
            }}
            toolBarRender={() => {
                return [
                    <Button
                        type="primary"
                        key="save"
                        onClick={() => {
                            submit(dataSource);
                        }}
                    >
                        保存「 {title} 」
                    </Button>,
                ];
            }}
            editable={{
                type: 'multiple',
                editableKeys,
                actionRender: (_row, _config, defaultDoms) => {
                    return [defaultDoms.delete];
                },
                onValuesChange: (_record, recordList) => {
                    setDataSource(recordList);
                },
                onChange: setEditableRowKeys,
            }}
        />
    )
}

export default Data;